import { getEndDate, getStartDate, orginazation_name } from '../store/constant';
import { getDateRange, getFormattedDate } from './date.utils';
import { capitalizeFirstLetter } from './forms.utils';

export const redirectToPage = (value, router) => {
  switch (value?.type) {
    case 'Hospital':
      return `/overview/hospital?q=${value?.id}`;

    case 'Medication':
      return `/utilization?medication=${value?.genericName}`;

    case 'Physician':
      return `/physician?q=${value?.id}`;

    case 'Theraputic':
      return `/utilization?theraputic=${value?.normalizedDrg}${
        value?.description
          ? `&therapeuticName=${JSON.stringify(value?.description)}`
          : ''
      }`;

    default:
      return router?.pathname || '';
  }
};

export const getAllDays = ({
  count,
  userInputStartDate = '',
  userInputEndDate = '',
  multipleOf,
  local
}) =>
  getDateRange({
    frequency: 'Daily',
    startDate: new Date(userInputStartDate || getStartDate(orginazation_name)),
    endDate: new Date(userInputEndDate || getEndDate(orginazation_name)),
    count,
    multipleOf,
    local
  }).reverse();

export const getAllWeeks = ({
  count,
  userInputStartDate = '',
  userInputEndDate = '',
  multipleOf,
  local
}) => {
  return getDateRange({
    frequency: 'Weekly',
    startDate: new Date(userInputStartDate || getStartDate(orginazation_name)),
    endDate: new Date(userInputEndDate || getEndDate(orginazation_name)),
    count,
    multipleOf,
    local
  }).reverse();
};

export const getAllMonths = ({
  count,
  userInputStartDate = '',
  userInputEndDate = '',
  multipleOf,
  local
}) =>
  getDateRange({
    frequency: 'Monthly',
    startDate: new Date(userInputStartDate || getStartDate(orginazation_name)),
    endDate: new Date(userInputEndDate || getEndDate(orginazation_name)),
    count,
    multipleOf,
    local
  }).reverse();

export const getParamsHospitalOverviewCharts = ({
  hospitalId,
  frequency,
  range,
  allWeeks,
  allMonths,
  allDays
}) => {
  const params = {};
  params.size = 20;

  if (hospitalId) {
    params.hospitalIds = [hospitalId];
  }

  if (frequency) {
    params.frequency = frequency;
  }

  if (frequency === 'Daily') {
    params.startDate = getFormattedDate(allDays[range[1]]);
    params.endDate = getFormattedDate(allDays[range[0]]);
  }

  if (frequency === 'Weekly') {
    params.startDate = getFormattedDate(allWeeks[range[1]]);
    params.endDate = getFormattedDate(allWeeks[range[0]]);
  }

  if (frequency === 'Monthly') {
    params.startDate = getFormattedDate(allMonths[range[1]]);
    params.endDate = getFormattedDate(allMonths[range[0]]);
  }

  return params;
};
export const handleRangeOverViewCharts = (
  type,
  setRange,
  range,
  frequency,
  initialRange,
  allWeeks,
  allMonths,
  allDays
) => {
  const dataLength =
    frequency === 'Daily'
      ? allDays.length
      : frequency === 'Weekly'
      ? allWeeks.length
      : allMonths.length;

  if (type === 'increase') {
    if (
      range[0] + initialRange[1] < dataLength &&
      range[1] + initialRange[1] < dataLength
    ) {
      setRange([range[0] + initialRange[1], range[1] + initialRange[1]]);
    } else if (
      range[0] + initialRange[1] < dataLength &&
      range[1] + initialRange[1] > dataLength
    ) {
      setRange([range[0] + initialRange[1], dataLength]);
    } else {
      setRange(range);
    }
  } else if (type === 'decrease') {
    if (range[0] - initialRange[1] > 0 && range[1] - initialRange[1] > 0) {
      setRange([range[0] - initialRange[1], range[1] - initialRange[1]]);
    } else if (
      range[1] - initialRange[1] > 0 &&
      range[0] - initialRange[1] <= 0
    ) {
      setRange([0, range[1] - initialRange[1]]);
    } else {
      setRange(range);
    }
  }
};

export const getFormattedDataForGraphs = (
  result = [],
  range,
  frequency,
  allWeeks,
  allMonths,
  allDays
) => {
  let allDates = [];

  if (frequency === 'Daily') {
    allDates = allDays.slice(range[0], range[1]);
  }

  if (frequency === 'Weekly') {
    allDates = allWeeks.slice(range[0], range[1]);
  }

  if (frequency === 'Monthly') {
    allDates = allMonths.slice(range[0], range[1]);
  }

  const allResults = [];
  allDates.forEach(x => {
    allResults.push(
      result.find(itm => getFormattedDate(parseInt(itm.date)) === x)?.value || 0
    );
  });

  return {
    allValues: allResults.reverse(),
    allDates: allDates.reverse()
  };
};

export const getTopThreeHospitals = allHospitalOptions => {
  const hospitals = [];

  if (allHospitalOptions?.length > 3) {
    for (let i = 0; i < allHospitalOptions?.length; i++) {
      hospitals.push(allHospitalOptions[i]);
    }

    return hospitals;
  }

  return allHospitalOptions;
};

export const getFormattedDataForGraphsOverviewHomePage = (
  result = [],
  apisToBeCalled,
  range,
  frequency,
  allWeeks,
  allMonths,
  allDays
) => {
  let allDates = [];

  if (frequency === 'Daily') {
    allDates = allDays.slice(range[0], range[1]);
  }

  if (frequency === 'Weekly') {
    allDates = allWeeks.slice(range[0], range[1]);
  }

  if (frequency === 'Monthly') {
    allDates = allMonths.slice(range[0], range[1]);
  }

  const allResults = apisToBeCalled?.map(item => {
    const values = [];
    allDates.forEach(x => {
      values.push(
        result.find(itm => {
          return (
            getFormattedDate(
              !isNaN(itm.date) ? parseInt(itm.date) : itm?.date
            ) === x && itm.id === item.value
          );
        })?.value || 0
      );
    });
    return {
      name: item?.label,
      data: values.reverse()
    };
  });

  return {
    series: allResults,
    dates: allDates.reverse()
  };
};

export const handleRangeForGraphsOverviewHomePage = (
  type,
  setRange,
  range,
  dataLength,
  initialRange
) => {
  if (type === 'increase') {
    if (
      range[0] + initialRange[1] < dataLength &&
      range[1] + initialRange[1] < dataLength
    ) {
      setRange([range[0] + initialRange[1], range[1] + initialRange[1]]);
    } else if (
      range[0] + initialRange[1] < dataLength &&
      range[1] + initialRange[1] >= dataLength
    ) {
      setRange([range[0] + initialRange[1], dataLength]);
    } else {
      setRange(range);
    }
  } else if (type === 'decrease') {
    if (range[0] - initialRange[1] > 0 && range[1] - initialRange[1] > 0) {
      setRange([range[0] - initialRange[1], range[1] - initialRange[1]]);
    } else if (
      range[1] - initialRange[1] > 0 &&
      range[0] - initialRange[1] <= 0
    ) {
      setRange([0, range[1] - initialRange[1]]);
    } else {
      setRange(range);
    }
  }
};

export const getParamsForGraphsOverviewHomePage = (
  hospitalsToBeCalled = [],
  frequency,
  range,
  allWeeks,
  allMonths,
  allDays
) => {
  let params = {};
  if (hospitalsToBeCalled?.length) {
    params.hospitalIds = hospitalsToBeCalled?.map(item => item?.value);
  }

  params.size = 40;

  if (frequency) {
    params.frequency = frequency;
  }

  if (frequency === 'Daily') {
    params.startDate = getFormattedDate(allDays[range[1]]);
    params.endDate = getFormattedDate(allDays[range[0]]);
  }

  if (frequency === 'Weekly') {
    params.startDate = getFormattedDate(allWeeks[range[1]]);
    params.endDate = getFormattedDate(allWeeks[range[0]]);
  }

  if (frequency === 'Monthly') {
    params.startDate = getFormattedDate(allMonths[range[1]]);
    params.endDate = getFormattedDate(allMonths[range[0]]);
  }

  return params;
};

export const collapseTitleIfTitleIsGreaterThanLimitCharacters = (
  title,
  limit = 25
) => {
  if (!title) {
    return ''; // Return an empty string if title is null or undefined
  }

  if (title.length <= limit) {
    return title; // Return original title if it's within the limit
  }

  const words = title.split(' ');

  if (words.length === 1) {
    let collapsedTitle = '';
    const finalLength = title.length < limit ? title.length : limit;

    for (let i = 0; i < finalLength; i++) {
      if (title[i]) {
        collapsedTitle += title[i];
      }
    }

    return finalLength === limit ? collapsedTitle + '...' : collapsedTitle;
  }

  let collapsedTitle = '';
  let currentLength = 0;

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const wordLength = word.length;

    if (currentLength + wordLength <= limit) {
      collapsedTitle += word + ' ';
      currentLength += wordLength + 1;
    } else if (currentLength + wordLength > limit && i == 0) {
      collapsedTitle += word + ' ';
      break;
    } else {
      break;
    }
  }

  collapsedTitle = collapsedTitle.trim(); // Remove trailing space

  return collapsedTitle.length === title.length
    ? collapsedTitle
    : collapsedTitle + '...';
};

export const getLengthAccordingToSelectedHospital = (
  selectedHospitals = []
) => {
  if (selectedHospitals?.length > 1) {
    return 20 - Math.pow(2, selectedHospitals?.length);
  }
  return 20;
};

export const formatTextFor_AndRemove_AndCapitalizeLetter = (
  title = '',
  capitalizeFirstLetter
) => {
  const splittedTitle =
    typeof capitalizeFirstLetter === 'function'
      ? capitalizeFirstLetter(title)?.split('_')
      : title?.split('_');
  return {
    title:
      splittedTitle?.length > 1
        ? splittedTitle
            ?.map((t, ind) =>
              splittedTitle?.length - 1 === ind ? `(${t.toUpperCase()})` : t
            )
            ?.join(' ')
        : title,
    route: splittedTitle?.length > 1 ? splittedTitle[1].toUpperCase() : '',
    splittedTitle: splittedTitle?.length > 1 ? splittedTitle[0] : ''
  };
};

export const getFormattedFromDateYearMonth = date => {
  let newDate = '';
  for (let i = 0; i < date?.length; i++) {
    if (i < 4) {
      newDate += date[i];
      if (i === 3) {
        newDate += '-';
      }
    } else {
      newDate += date[i];
    }
  }

  if (newDate) {
    const date = newDate.split('-');
    const yearLastTwoDigits = date[0].toString().slice(-2);
    const monthAbbreviations = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC'
    ];

    return `${monthAbbreviations[parseInt(date[1]) - 1]}-${yearLastTwoDigits}`;
  }
};

export const sortInsiderDatesData = (data = []) => {
  return data?.map(itm => ({
    ...itm,
    data: itm?.data?.sort((a, b) =>
      a?.date
        ? parseInt(a.date) - parseInt(b.date)
        : parseInt(a.month) - parseInt(b.month)
    )
  }));
};

export function convertQueryObjectToPath(queryObject) {
  const queryString = Object.keys(queryObject)
    .map(
      key =>
        `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
    )
    .join('&');

  return `?${queryString}`;
}

export function redirectWithoutRefreshing(
  router,
  filters,
  shallow = true,
  queriesToBeRemoved = []
) {
  // Get the current query parameters
  const currentQuery = { ...router.query };

  // Iterate over the filters object
  Object.entries(filters).forEach(([filterName, filterValues]) => {
    // Check if filterValues exist and are not empty
    if (filterValues?.length) {
      // Encode and stringify the filterValues
      const encodedFilterValues = encodeURIComponent(
        JSON.stringify(filterValues)
      );

      // Check if the filterName already exists in the query parameters
      if (currentQuery[filterName] !== undefined) {
        // If it exists, update its value
        currentQuery[filterName] = encodedFilterValues;
      } else {
        // If it doesn't exist, add it to the query parameters
        currentQuery[filterName] = encodedFilterValues;
      }
    } else {
      // If filterValues are empty, remove the query parameter
      delete currentQuery[filterName];
    }
  });

  if (queriesToBeRemoved?.length) {
    queriesToBeRemoved?.forEach(data => delete currentQuery[data]);
  }

  // Construct the new URL with updated query parameters
  router.push(
    {
      pathname: router.pathname,
      query: currentQuery
    },
    undefined,
    { shallow: shallow }
  );
}

export const decodeValue = encodedFilterValues => {
  if (encodedFilterValues) {
    const decodedFilterValues = decodeURIComponent(encodedFilterValues);
    return isJson(decodedFilterValues)
      ? JSON.parse(decodedFilterValues)
      : decodedFilterValues;
  }
  return;
};

export const encodeValue = value => {
  return encodeURIComponent(JSON.stringify(value));
};

function isJson(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export const candleChartColoring = (low = 0, high = 0, value = 0) => {
  if (parseFloat(value) > 0 && parseFloat(high) > 0) {
    if (parseFloat(value) <= parseFloat(high)) {
      return {
        candleColor: 'rgb(88, 213, 126)',
        titleColor: 'rgb(88, 213, 126)'
      };
    } else {
      return {
        candleColor: 'rgb(255, 87, 51)',
        titleColor: 'rgb(56, 126, 208)',
        waveLine: true
      };
    }
  }
};
