import * as React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material';
import { checkRequiredSized } from '../../utils/resposive.utils';

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[4],
    fontSize: 11,
    padding: '0',
    maxWidth: 'none'
  }
}));

export default function NewTooltip({
  children: Children,
  placement,
  title,
  handleTooltipClose,
  handleTooltipOpen,
  open,
  mouseEnterOption = false,
  ...props
}) {
  const isSizeXS = checkRequiredSized('sm');

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <LightTooltip
            title={title}
            placement={placement}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            {...props}
            PopperProps={{
              disablePortal: true
            }}
            onClose={handleTooltipClose}
          >
            <span
              style={{ cursor: 'pointer' }}
              onClick={handleTooltipOpen}
              onMouseEnter={mouseEnterOption ? handleTooltipOpen : () => ''}
            >
              {Children}
            </span>
          </LightTooltip>
        </div>
      </ClickAwayListener>
    </>
  );
}
